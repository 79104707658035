<template>
  <div>
    <ion-card color="light">
      <ion-fab vertical="top" horizontal="end">
        <ion-fab-button class="ion-text-end btn-modifyProfil" @click.prevent="goToStudentEdit" shape="round"  size="small" color="light" >
          <ion-icon src="/assets/icon/pen-solid.svg" ></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-card-header v-if="currentClassroom">
        <ion-card-subtitle>{{currentClassroom.name}}</ion-card-subtitle>
        <ion-card-title>{{ currentStudent.first_name }} {{ currentStudent.last_name }}</ion-card-title>
      </ion-card-header>
      <ion-card-content v-if="currentWallet">
        <p>Solde du compte: <strong>{{currentWallet.total_balance}}&nbsp;&euro;</strong></p>
        <p>Somme  des frais à venir: <strong>{{currentWallet.next_amount}}&nbsp;&euro;</strong></p>
        <p><strong>Solde disponible: {{currentWallet.available_balance}}&nbsp;&euro;</strong></p>
        <br>
        <ion-button expand="block" @click.prevent="goToPayment" v-if="isLegalTutor">
          <ion-icon :icon="cardOutline"></ion-icon>
          &nbsp;&nbsp;Ajouter de l'argent
        </ion-button>
        <ion-button expand="block" @click.prevent="goToLegalTutorPayment" v-else-if="isTeacher">
          <ion-icon :icon="cardOutline"></ion-icon>
          &nbsp;&nbsp;Recevoir de l'argent
        </ion-button>
        <ion-button expand="block" @click.prevent="refundWalletConfirm" v-if="currentWallet.available_balance > 0 && isDirector" color="danger">
          <ion-icon :icon="exitOutline"></ion-icon>
          &nbsp;&nbsp;Apurer le compte
        </ion-button>
        <ion-button v-if="$route.fullPath.substring($route.fullPath.lastIndexOf('/') + 1) !== 'transactions'" expand="block" @click.prevent="goToTransactions">
          <ion-icon :icon="wallet"></ion-icon>
          &nbsp;&nbsp;Transactions
        </ion-button>
        <ion-button v-if="$route.fullPath.substring($route.fullPath.lastIndexOf('/') + 1) !== 'futur'" expand="block" @click.prevent="goToFutur">
          <ion-icon :icon="wallet"></ion-icon>
          &nbsp;&nbsp;Futur
        </ion-button>
      </ion-card-content>
    </ion-card>

  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonFab, IonFabButton,
  IonButton,
  alertController,
  toastController
} from '@ionic/vue';
import { cardOutline, wallet, exitOutline } from "ionicons/icons";
export default {
  name: "StudentHeader",
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle, IonCardSubtitle, IonIcon, IonFab, IonFabButton,
    IonButton
  },
  props: {
    currentStudent: {
      type: Object,
      required: true
    },
    currentClassroom: {
      type: Object,
      required: true
    },
    currentWallet: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      cardOutline, wallet, exitOutline
    };
  },
  methods: {
    goToStudentEdit() {
      if(this.isDirector){
        this.$router.push('/school/'+ this.currentClassroom.school.uuid +'/students/' + this.currentStudent.uuid + '/edit' )
      }else{
        this.$router.push('/student/' + this.currentStudent.uuid + '/edit' )
      }
    },
    goToLegalTutorPayment(){
      this.$router.push('/classroom/student/' + this.currentStudent.uuid + '/receive_payment')
    },
    goToPayment(){
      this.$router.push('/student/' + this.currentStudent.uuid + '/make_payment')
    },
    goToTransactions(){
      this.$router.push('/student/' + this.currentStudent.uuid + '/transactions')
    },
    goToFutur(){
      this.$router.push('/student/' + this.currentStudent.uuid + '/futur')
    },
    async refundWalletConfirm(){
      const alertMsg = {
          message: `Êtes-vous sûr de vouloir apurer le compte de ${this.currentStudent.first_name} ${this.currentStudent.last_name} ?`,
          buttons: [
            {
              text: "Annuler",
            },
            {
              text: "Confirmer",
              cssClass: "btn-danger",
              handler: ()=> {
                this.refundWallet()
              }
            }
          ]
        }
        const alertItem = await alertController.create(alertMsg);
        return alertItem.present();
    },
    refundWallet(){
      this.$store.dispatch('students/refundWallet', {student_uuid: this.currentStudent.uuid}).then(async () => {
        this.$store.dispatch('students/syncWallet', {student_uuid: this.currentStudent.uuid, force: true})
        const toast = await toastController
          .create({
            message: 'Le compte a été apuré',
            duration: 2000
          })
        return toast.present();
      }).catch((error) => {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.message)
        } else {
          alert('Une erreur est survenue')
        }
      })
    },
  },
  computed: {
    classrooms() {
      return this.$store.getters['home/getUserClassrooms'];
    },
    isLegalTutor(){
      return this.$store.getters['home/isLegalTutor'](this.currentStudent.uuid)
    },
    isTeacher(){
      return this.$store.getters['home/isTeacher'](this.currentClassroom.uuid)
    },
    isDirector(){
      return this.$store.getters['home/isDirector'](this.currentClassroom.school.uuid)
    }
  }
}
</script>

<style scoped>

</style>